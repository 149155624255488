<template>
    <div class="card-container">
        <div class="header-wrapper">
            <div class="close" @click.prevent="handleClose">×</div>
            <div class="title">{{item.sname}}</div>
            <div class="info">{{item.sdesc}}</div>
        </div>
        <div class="center-btn">
            Store Pass
            <img class="jumpUrl" @click="copy_share_url" :data-clipboard-text='share_url' :src="share_img" style="width: 20px; vertical-align: middle; padding-left: 5px;"/>
        </div>
        <div v-if="msg" class="msg-box"><span class="msg-type"></span> {{msg}}</div>
        <div class="point-wrapper">
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide>
                    <div class="card-wrapper">
                        <div class="qrcode" ref="qrCodeUrl2"></div>
                    </div>
                </swiper-slide>
                <swiper-slide>
                    <div class="card-wrapper">
                        <div class="number-wrapper">
                            <div class="point-number-wrapper">
                                <div class="point">
                                    <IOdometer class="iOdometer" :value="num.point" />
                                </div>
                                <div class="unit">pts</div>
                            </div>
                            <div class="point-number-wrapper credit">
                                <div class="point">
                                    <IOdometer class="iOdometer" :value="num.credit" />
                                </div>
                                <div class="unit">credits</div>
                            </div>
                            <div class="point-number-wrapper member">
                                <div class="point">
                                    <IOdometer class="iOdometer" :value="num.days" />
                                </div>
                                <div class="unit">days</div>
                            </div>
                        </div>
                    </div>
                    
                </swiper-slide>
                <swiper-slide>
                    <div class="card-wrapper">
                        <i class="icon-span" :class="{'icon-span-select' : !(isShow && !is_loading)}"></i>
                        <div class="sign-in-succss" v-if="isShow && !is_loading">Success!</div>
                        <div :class="setClass()" v-else>
                            <vButton :text="signText" @btnClick="sign" />
                        </div>
                        <div class="sign-in-date">{{item && item.str_date ? item.str_date : ''}}</div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>



            <!-- <div class="card-wrapper">
              <div class="btn-wrapper">
                    <div class="child">
                        <div class="icon"><img :src="REWARD_EMPTY_SRC" alt="" /></div>
                        <div class="text">Check Rewards</div> 
                    </div>
                    <div class="child" @click.prevent="showOrderHistory">
                        <div class="icon"><img :src="TIME_SRC" alt="" /></div>
                        <div class="text">Order History</div>
                    </div>
                </div>
            </div> -->
            <div class="footer" v-if="walletSrc">
                <div class="save-wallet-wrapper">
                    <a :href="item.apple_wallet_url" target="_blank">
                        <div class="wallet" :style="{backgroundImage: 'url('+ walletSrc +')', backgroundRepeat:'no-repeat',
                        backgroundSize:'100% 100%'}"></div>
                    </a>
                </div>
            </div>
        </div>
        <div class="order-container" v-show="orderHistory">
            <div class="order-wrapper">
                <div class="header-wrapper">
                    <div class="close" @click.prevent="closeOrderHistory">×</div>
                    <div class="title">Order History</div>
                </div>
                <div class="order-body">
                    <div v-if="is_loading" style="text-align: center;">loading...</div>
                    <div v-for="(item, i) in orderList" :key="i" class="item-wrapper"
                        @click.prevent="handleOrderClick(item, i)">
                        <vFormItemH :labelBolder="true" :textBolder="false" :extra="true">
                            <div slot="label">{{item.id ? 'ORDER ' : ''}}{{getUniqueId(item.id)}}</div>
                            <div slot="text">{{getDateText(item.createdate)}}</div>
                            <div slot="extra"><img :src="item.iconSrc ? item.iconSrc : PLUS_SRC" alt="" /></div>
                        </vFormItemH>
                        <div v-if="item.iconSrc === MINUS_SRC">
                            <div class="detail-wrapper">
                                <div class="detail-item">
                                    <div class="label txt">
                                        {{ item.payload && item.payload.name ? item.payload.name : ''}}</div>
                                    <div class="number txt"></div>
                                    <div
                                        :class="{'extra': true, 'txt': true, 'total': !isMinus(item), 'minus': isMinus(item)}">
                                        {{ item.point }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<style scoped>
    .card-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #FAF7F7;
        overflow: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
    }

    .card-container .header-wrapper {
        width: 375px;
        height: 106px;
        background-color: #F2F2F2;
        text-align: -webkit-center;
        color: #000;
        padding-top: 25px;
    }

    .header-wrapper .title {
        font-size: 24px;
        font-weight: bold;
        line-height: 30px;
        max-width: 200px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .header-wrapper .info {
        font-size: 12px;
        opacity: .8;
        line-height: 14px;
        max-width: 200px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-top: 12px;
    }

    .header-wrapper .close {
        position: absolute;
        text-align: -webkit-right;
        color: #08bcaa;
        font-size: 24px;
        width: 365px;
    }

    .center-btn {
        width: 146px;
        height: 48px;
        border-radius: 24px;
        font-size: 16px;
        margin-top: -24px;
        margin-left: 110px;
        text-align: center;
        line-height: 48px;
        overflow: hidden;
        background-color: #08bcaa;
        color: #fff;
    }

    .card-wrapper {
        height: 300px;
        margin: 29.7px 36px 36px 36px;
        border-radius: 12px;
        overflow-x: hidden;
        overflow-y: scroll;
        background-color: #fff;
        -webkit-overflow-scrolling: touch;
        transition: all .3s ease-in-out;
        box-shadow: 0 25px 25px rgba(0, 0, 0, .15);
    }

    .card-wrapper .header-wrapper {
        height: 160px;
        border-radius: 12px;
        background-color: aquamarine;
    }

    .card-wrapper .qrcode {
        text-align: -webkit-center;
        margin-top: 50px;
    }

    .point-wrapper {
        font-size: 24px;
        text-align: -webkit-center;
        color: #000;
        background-color: #FAF7F7;
    }

    .number-wrapper {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        height: 220px;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .point-number-wrapper {
        display: flex;
        justify-content: center;
        align-items: baseline;
    }

    .point-number-wrapper .point {
        font-size: 38px;
        color: #000;
        width: 50%;
        text-align: right;
        font-weight: bold;
    }

    .credit .point {
        color: #FFD700;
    }

    .member .point {
        color: #08bcaa;
    }

    .point-number-wrapper .unit {
        width: 50%;
        text-align: left;
        padding-left: 10px;
    }

    .point-wrapper .footer {
        height: 70px;
    }

    /* 签到按钮 */
    .sign-in-box div {
        width: 127px !important;
        background-color: #FFD700 !important;
    }

    .sign-in-box.disabled div {
        background-color: #aaa !important;
    }

    .sign-in-succss {
        font-size: 16px;
    }

    .sign-in-date {
        font-size: 16px;
        color: #aaa;
        margin-top: 20px;
    }

    .btn-wrapper {
        display: flex;
        align-items: center;
        font-size: 0.12rem;
        height: 60px;
        font-weight: normal;
    }

    .btn-wrapper .child {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .btn-wrapper .child .icon img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        margin-left: 20px;
    }

    .footer-btn-wrapper {
        padding-top: 20px;
    }

    /* 添加至钱包 */
    .save-wallet-wrapper {
        padding: 30.5px 64px 167px 64px;
        background-color: #FAF7F7;
    }

    .wallet {
        width: 247px;
        height: 52px;
    }

    /* order history */
    .order-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .68);
        z-index: 11;
        font-size: 16px;
    }

    .order-wrapper {
        position: fixed;
        top: 125px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        background-color: #fff;
        height: 100%;
    }

    .order-wrapper .header-wrapper {
        height: 70px;
        background-color: #fff;
    }

    .order-body {
        overflow-y: auto;
        height: 58%;
    }

    .order-body .item-wrapper .extra img {
        width: 14px;
        height: 14px;
    }

    .detail-item {
        display: flex;
        font-size: 12px;
        color: #9B9B9B;
        margin: 15px 18px;
    }

    .detail-item .txt {
        line-height: 15px;
    }

    .detail-item .label {
        flex: 0 0 55%;
        padding-left: 5%;
    }

    .detail-item .number {
        flex: 0 0 15%;
        text-align: right;
    }

    .detail-item .extra {
        flex: 0 0 20%;
        text-align: right;
        padding-right: 5%;
    }

    .total {
        color: #08BCAA;
    }

    .minus {
        color: red;
    }

    /* 签到动画效果 */
    .icon-span {
        margin: 50px 0 25px 0;
        display: inline-block;
        background-color: #fff;
        border-radius: 100%;
        border: 6px solid #ccc;
        position: relative;
        width: 65px;
        height: 65px;
        vertical-align: middle;
    }

    .icon-span::after {
        border: 6px solid #ccc;
        border-radius: 4px;
        border-color: #ccc;
        border-left: 0;
        border-top: 0;
        content: " ";
        top: 10px;
        left: 23px;
        position: absolute;
        width: 18px;
        height: 30px;
        -webkit-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
        -webkit-transition: -webkit-transform .2s;
        transition: -webkit-transform .2s;
        transition: transform .2s;
        transition: transform .2s, -webkit-transform .2s;
    }

    .icon-span-select {
        background-color: #fff;
        border-color: #FFD700;
    }

    .icon-span-select::after {
        border-color: #FFD700;
        -webkit-transform: rotate(45deg) scale(1);
        transform: rotate(45deg) scale(1);
    }
</style>

<script>
    import Vue from 'vue';
    import QRCode from 'qrcodejs2';
    import 'odometer/themes/odometer-theme-default.css';
    import 'swiper/dist/css/swiper.css';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import { TIME_SRC, REWARD_EMPTY_SRC, PLUS_SRC, MINUS_SRC, WALLET_FOR_PHONE, WALLET_FOR_IOS } from '../../consts/const.js';
    import { isIOS } from '../../kits/tools.js';
    import vFormItemH from '../../components/FormItemHorizon.vue';
    import IOdometer from 'vue-odometer';
    import { swiper, swiperSlide } from 'vue-awesome-swiper';
    import vButton from '../../components/Button.vue';
    import share_img from '../../assets/images/share.png';
    import Clipboard from 'clipboard';

    export default {
        name: 'v-pass-detail',
        components: { vFormItemH, IOdometer, swiper, swiperSlide, vButton },
        props: {},
        data() {
            let self = this;
            return {
                TIME_SRC, REWARD_EMPTY_SRC, PLUS_SRC, MINUS_SRC,
                walletSrc: isIOS() ? WALLET_FOR_IOS : undefined,
                item: {},
                orderHistory: false,
                orderList: [],
                carouselList: [{ name: 'qrcode' }, { name: 'txt' }],
                num: { point: 0, credit: 0, days: 1 },
                swiperOptions: {
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                        bulletActiveClass: 'bullet-active',
                    },
                    on: {
                        slideChangeTransitionEnd: function () {
                            let curIndex = this.activeIndex;
                            if (curIndex === 1) {
                                self.num = {
                                    point: self.item.point, credit: self.item.credit, days: (!self.item.days || self.item.days == 0) ? 1 : self.item.days
                                }
                            } else if (curIndex === 0) {
                                self.num = {
                                    point: 0, credit: 0, days: 1
                                };
                            }
                        },
                    },
                },
                signText: 'Checked in',
                disableSign: true,
                isShow: false,
                share_img: share_img,
                share_url: 'https://pass.minitable.net/#/login?sid=' + this.$route.query.sid,
                msg: ''
            }
        },
        created() {
        },
        mounted() {
            let data = this.$route.query;
            this.initCardDetail({
                data,
                success: (res) => {
                    this.item = res.item;
                    this.carouselList[1] = { name: 'txt', ...this.item };
                    this.creatQrCode(`${this.item.card_number}`);
                    this.getSignStatus(this.item);

                    this.share_url = this.share_url + '&invite_by=' + this.item.card_number;
                }
            });
        },
        computed: {
            ...mapGetters({
                is_loading: 'app/getLoading',
            }),
            swiper() {
                return this.$refs.mySwiper.$swiper
            }
        },
        methods: {
            showSucIcon(status) {
                this.isShow = status;
            },
            ...mapMutations({
                updateUser: 'app/SET_USER',
                setLoading: 'app/APP_SET_IF_LOADING',
                showMsg: 'app/APP_SET_MSG'
            }),
            ...mapActions({
                initOrderHList: 'app/initOrderHList',
                checkSignStatusByCard: 'app/checkSignStatusByCard',
                signByCard: 'app/signByCard',
                initCardDetail: 'app/initCardDetail',
                initCardList: 'app/initCardList',
            }),
            setClass() {
                return `sign-in-box ${this.disableSign && !this.is_loading ? 'disabled' : ''}`;
            },
            isMinus(item) {
                return item && item.point !== null && item.point !== undefined && item.point.toString() && item.point.toString().startsWith('-');
            },
            getDateText(dateStr) {
                if (!dateStr) {
                    return '';
                }
                const dateTemp = new Date(dateStr);
                return dateTemp.getFullYear() + '/' + (dateTemp.getMonth() + 1) + '/' + dateTemp.getDate();
            },
            getUniqueId(str) {
                return str && str.length > 12 ? `***${str.substr(str.length - 12)}` : str;
            },
            creatQrCode(card_number) {
                let qrcode = new QRCode(this.$refs.qrCodeUrl2, {
                    text: card_number,
                    width: 180,
                    height: 180,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                });
            },
            handleClose() {
                this.updateUser({ home: true });
                this.$router.replace('/pass');
            },
            closeOrderHistory() {
                this.orderHistory = false;
            },
            showOrderHistory() {
                this.initOrderHList({
                    data: { card_number: this.item.card_number }, success: (res) => {
                        this.orderList = res.list || [];
                    }
                });
                this.orderHistory = true;
            },
            handleOrderClick(item, i) {
                item.icon = item.icon === 'MINUS_SRC' ? 'PLUS_SRC' : 'MINUS_SRC';
                item.iconSrc = item.icon === 'PLUS_SRC' ? PLUS_SRC : MINUS_SRC;
                this.$set(this.orderList, i, item);
            },
            updateSignBtn(signStatus) {
                if (signStatus === 'loading') {
                    this.signText = 'Checking in...';
                    this.disableSign = true;
                    return;
                }
                if (signStatus) {
                    this.signText = 'Checked in';
                    this.disableSign = true;
                } else {
                    this.signText = 'Check in';
                    this.disableSign = false;
                }
                this.showSucIcon(signStatus);
            },
            sign() { // 提交签到
                this.showSucIcon(!this.isShow);
                if (this.disableSign) {
                    return;
                }
                this.updateSignBtn('loading');
                this.signByCard({
                    data: {
                        card_number: this.item.card_number,
                        sid: this.item.sid
                    },
                    success: (res) => {
                        this.updateSignBtn(res.success);
                    },
                    fail: (e) => {
                        this.updateSignBtn(false);
                    }
                });

            },
            getSignStatus(item) { // 获取签到信息
                this.checkSignStatusByCard({
                    data: {
                        card_number: item.card_number,
                        sid: item.sid
                    },
                    success: (res) => {
                        this.updateSignBtn(res.checkedin);
                        this.item.str_date = res.str_date;
                    }
                });
            },
            copy_share_url(e) {
                var clipboard = new Clipboard('.jumpUrl');
                clipboard.on("success", () => {
                    this.msg = 'Copy succeeded';
                    clipboard.destroy();
                    setTimeout(() => {
                        this.msg = '';
                    }, 2000);
                });
                clipboard.on("error", () => {
                    this.msg = 'Not support';
                    clipboard.destroy();
                    setTimeout(() => {
                        this.msg = '';
                    }, 2000);
                });
            }
        }
    };
</script>