<template>
    <div :class="{'form-item': true, 'form-item-border': noborder }">
        <div :class="{'label': true, 'label-bolder': labelBolder, 'label-extra': extra}">
            <slot name="label"></slot>
        </div>
        <div :class="{'text': true, 'text-lighter': !textBolder, 'text-extra': extra}">
            <slot name="text"></slot>
        </div>
        <div class="extra" v-if="extra">
            <slot name="extra"></slot>
        </div>
    </div>
</template>
<style scoped>
    .form-item {
        height: 48px;
        display: flex;
        flex: 1;
        font-size: 14px;
        align-items: center;
        color: #000;
        vertical-align: left;
        margin: 0 18px;
        border-bottom: 1px solid #eee;
    }
    .form-item-border {
        border-bottom: 0;
    }

    .form-item .label {
        width: 26%;
        font-size: 12px;
        color: #9B9B9B;
        line-height: 14px;
        padding-left: 2%;
    }

    .form-item .label-bolder {
        color: #000;
    }

    .form-item .text-lighter {
        color: #DEE3E9;
        font-size: 10px;
    }

    .form-item .text {
        line-height: 17px;
    }

    .form-item .extra {
        flex: 0 0 20%;
        text-align: right;
        padding-right: 5%;
    }

    .form-item .label-extra {
        flex: 0 0 60%;
    }
    .form-item .text-extra {
        flex: 0 0 15%;
    }

</style>
<script>
    export default {
        name: 'v-horizon-item',
        props: {
            extra: { type: Boolean, default: false },
            labelBolder: { type: Boolean, default: false },
            textBolder: { type: Boolean, default: true },
            noborder: { type: Boolean, default: false}
        },
        data() {
            return {
            }
        },
        created() {
        },
        mounted() {

        },
        computed: {
        },
        methods: {
        }
    };
</script>